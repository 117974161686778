import { dashboardTypes } from "./dashboardTypes";

export function onSegmentSelected({ store, selectedSegment }) {
  store.rootStore.userInfoStore.setCurrentSegment(selectedSegment);
  if (store.dashboardType === dashboardTypes.SUPPORT) {
    store.router.history.push(`/support/getUserCoupons`);
  } else if (store.dashboardType === dashboardTypes.ORGANIZATION) {
    store.router.history.push(`/organization/home`);
  } else if (store.dashboardType === dashboardTypes.SUPPLIERS) {
    store.router.history.push(`/suppliers/home`);
  }
}
