import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";

export function ButtonWithUnderline({
  buttonWithUnderlineText,
  buttonWithUnderlineOnClick,
}) {
  const useStyles = makeStyles((theme) => ({
    submitButton: {
      marginTop: theme.spacing(2),
      textDecorationLine: "underline",
      "&:hover": {
        cursor: "pointer",
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Grid
      className={classes.submitButton}
      onClick={buttonWithUnderlineOnClick}
      type="submit"
    >
      <Typography variant="subtitle2">{buttonWithUnderlineText}</Typography>
    </Grid>
  );
}
