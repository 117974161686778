import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  submitButton2: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonsDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export function Form(props) {
  const {
    className,
    onField1Change,
    onField2Change,
    field1Value,
    field2Value,
    field1Label,
    field2Label,
    submitButtonLabel,
    submitButton2Label,
    ...rest
  } = props;
  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (props.onSubmit) {
      props.onSubmit({ field1Value, field2Value });
    }
  };

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        {field1Label ? (
          <TextField
            error={false}
            fullWidth
            helperText={""}
            label={field1Label}
            name="email"
            onChange={onField1Change}
            value={field1Value}
            variant="outlined"
          />
        ) : null}
        {field2Label ? (
          <TextField
            error={false}
            fullWidth
            helperText={""}
            label={field2Label}
            name="password"
            onChange={onField2Change}
            type="password"
            value={field2Value}
            variant="outlined"
          />
        ) : null}
      </div>
      <div className={classes.buttonsDiv}>
        <Button
          className={classes.submitButton}
          disabled={false}
          size="large"
          type="submit"
          variant="contained"
        >
          {submitButtonLabel}
        </Button>
        {submitButton2Label ? (
          <Button
            className={classes.submitButton2}
            disabled={false}
            size="large"
            type="submit"
            variant="contained"
            onClick={props.onSubmit2}
          >
            {submitButton2Label}
          </Button>
        ) : null}
      </div>
    </form>
  );
}
