import { t } from "common/localization/translate";

export const errorCodes = {
  USER_NOT_FOUND: 1,
  NO_PERMISSION: 2,
  NO_BUSINESSES: 3,
  NOT_ALLOWED_SEGMENT: 4,
  PASSWORD_RESET_REQUIRED: 5,
  MAINTENANCE_MODE: 6,
  LIMITER_EXCEEDED: 7,
  NO_PHONE_IN_DB: 8,
};

export function getErrorTextByErrorCode(errorCode) {
  let text;
  if (errorCode === errorCodes.PASSWORD_RESET_REQUIRED) {
    text = t("screenLogin.errorText.resetPassword");
  } else if (errorCode === errorCodes.MAINTENANCE_MODE) {
    text = t("screenLogin.errorText.maintenence");
  } else if (errorCode === errorCodes.NO_PHONE_IN_DB) {
    text = t("screenLogin.errorText.noPhone");
  } else if (errorCode === errorCodes.LIMITER_EXCEEDED) {
    text = t("screenLogin.errorText.loginLimiter");
  } else {
    text = t("screenLogin.errorText");
  }
  return text;
}
