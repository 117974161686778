import { t } from "common/localization/translate";
import { resetPasswordSendEmail } from "screens/organization/login/store/server/resetPassword";

export async function onResetPassword({ store }) {
  if (!store.email) {
    store.errorText = t("screenLogin.editPassword.errorText");
    return;
  }
  store.errorText = null;
  store.isShowSuccessResetPasswordPopUp = true;
  await resetPasswordSendEmail({ email: store.email });
}
