import React from "react";
import { onSubmitEmailAndPassword } from "./logic/onSubmitEmailAndPassword";
import { initStore } from "./logic/initStore";
import { onSegmentSelected } from "./logic/onSegmentSelected";
import { onResetPassword } from "./logic/onResetPassword";
import { onVerifyOtpForLogin } from "./logic/onVerifyOtpForLogin";
import { onGoBack } from "./logic/onGoBack";

class ScreenLoginStore {
  constructor(rootStore, router) {
    initStore(this, rootStore, router);
  }

  onSegmentSelected = ({ selectedSegment }) => {
    onSegmentSelected({ store: this, selectedSegment });
  };

  onSubmitEmailAndPassword = async () => {
    await onSubmitEmailAndPassword({ store: this });
  };

  onVerifyOtpForLogin = async () => {
    await onVerifyOtpForLogin({ store: this });
  };

  onResetPassword = async () => {
    await onResetPassword({ store: this });
  };

  onGoBack = () => {
    onGoBack({ store: this });
  };
}

export function createScreenLoginStore(rootStore, router) {
  const store = new ScreenLoginStore(rootStore, router);
  return store;
}
const ScreenLoginStoreContext = React.createContext({});
export const ScreenLoginStoreProvider = ScreenLoginStoreContext.Provider;
export const useScreenLoginStore = () =>
  React.useContext(ScreenLoginStoreContext);
