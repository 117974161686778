import {
  createLocalDataObject,
  setLocalData,
} from "commonStores/analytics/localStorage/localPersistence";
import { getStorageKeyByDashobardType } from "commonStores/userInfoStore/localStorage/getStorageKeyByDashobardType";

export async function saveLocalData(
  dashboardType,
  currentSegment,
  currentBusiness
) {
  const key = getStorageKeyByDashobardType(dashboardType);
  const data = createLocalDataObject(currentSegment, currentBusiness);
  await setLocalData(key, data);
}
