import { httpCall } from "common/httpCall";
import { saveLocalData } from "./saveLocalData";
import { t } from "common/localization/translate";

export async function onVerifyOtpForLogin({ store }) {
  const { isSuccess, errorCode, extra } = await httpCall(
    "dashboards/verifyOtpForLogin",
    {
      otpCode: store.otpCode,
      password: store.password,
      email: store.email,
      dashboardType: store.dashboardType,
    }
  );
  if (isSuccess) {
    const { allowedSegments, currentSegment, currentBusiness } = extra;
    const segmentToShow =
      currentSegment || allowedSegments ? allowedSegments[0] : null;
    store.rootStore.userInfoStore.initProperties({
      segmentId: segmentToShow,
      businessId: currentBusiness,
      dashboardType: store.dashboardType,
    });
    saveLocalData(store.dashboardType, segmentToShow, currentBusiness);
    store.password = null;
    store.email = null;
    store.otpCode = null;
    store.allowedSegments = allowedSegments;
    store.wizardStep = "chooseSegment";
  } else {
    if (errorCode == "CODE_DOES_NOT_MATCH") {
      store.errorText = t(
        "OtpCode:errorText.invalidCode",
        null,
        store.language
      );
    } else {
      store.errorText = "שגיאה";
    }
  }
}
