import { Card, CardContent } from "@mui/material";
import { ChooseSegmentCard } from "screens/organization/login/components/chooseSegmentCard";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useScreenLoginStore } from "screens/loginV2/store";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 400,
    maxWidth: "100%",
    overflow: "unset",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
}));

export const ChooseSegment = observer(() => {
  const classes = useStyles();
  const store = useScreenLoginStore();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <ChooseSegmentCard
          onSegmentSelected={store.onSegmentSelected}
          allowedSegments={store.allowedSegments}
        />
      </CardContent>
    </Card>
  );
});
