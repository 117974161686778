import { t } from "common/localization/translate";
import { login } from "../server/login";
import { dashboardTypes } from "./dashboardTypes";
import { saveLocalData } from "./saveLocalData";
import { getErrorTextByErrorCode } from "screens/organization/login/store/logic/getErrorTextByErrorCode";

export async function onSubmitEmailAndPassword({ store }) {
  const { email, password, dashboardType } = store;
  if (!email || !password) {
    store.errorText = t("screenLogin.description");
    return;
  }
  const { isSuccess, extra, errorCode } = await login(
    { email, password },
    dashboardType
  );

  if (isSuccess) {
    if (
      dashboardType === dashboardTypes.SUPPORT ||
      dashboardType === dashboardTypes.SUPPLIERS ||
      dashboardType === dashboardTypes.ORGANIZATION
    ) {
      store.lastDigitsPhone = extra?.lastDigitsPhone;
      store.wizardStep = "enterOtp";
      store.errorText = null;
    } else {
      const { allowedSegments, currentSegment, currentBusiness } = extra;
      const segmentToShow =
        currentSegment || allowedSegments ? allowedSegments[0] : null;
      store.rootStore.userInfoStore.initProperties({
        segmentId: segmentToShow,
        businessId: currentBusiness,
        dashboardType,
      });
      saveLocalData(dashboardType, segmentToShow, currentBusiness);
      store.allowedSegments = allowedSegments;
      const newPath = `/${dashboardType}/home`;
      store.router.history.push(newPath);
    }
  } else {
    if (extra && extra.isResetPasswordNeeded) {
      this.isResetPasswordNeeded = extra.isResetPasswordNeeded;
      this.appType = extra.appType;
      this.email = extra.email;
    }
    const errorText = getErrorTextByErrorCode(errorCode);
    store.errorText = errorText;
  }
}
