import { makeObservable, observable } from "mobx";
import { getDashboardByPathName } from "./getDashboardByPathName";
import { dashboardTypes } from "./dashboardTypes";
import { languages } from "common/localization/languages";

export function initStore(store, rootStore, router) {
  store.rootStore = rootStore;
  store.router = router;
  store.wizardStep = "emailAndPassword";
  store.errorText = "";
  store.appType = null;
  store.isResetPasswordNeeded = false;
  store.isShowSuccessResetPasswordPopUp = false;
  store.allowedSegments = null;
  store.email = null;
  store.password = null;
  store.otpCode = null;
  store.lastDigitsPhone = null;
  store.dashboardType = getDashboardByPathName(router.location.pathname);
  store.language =
    store.dashboardType === dashboardTypes.ORGANIZATION
      ? languages.ENGLISH
      : languages.HEBREW;
  makeObservable(store, {
    wizardStep: observable,
    errorText: observable,
    appType: observable,
    isResetPasswordNeeded: observable,
    isShowSuccessResetPasswordPopUp: observable,
    allowedSegments: observable,
    email: observable,
    password: observable,
    otpCode: observable,
    dashboardType: observable,
    lastDigitsPhone: observable,
  });
}
